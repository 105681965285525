<template>
  <a :href="props.to"><slot /></a>
</template>
<script setup lang="ts">
const props = defineProps({
  to: {
    type: String,
    required: true,
  },
});
</script>
